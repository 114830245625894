<template>
  <form
    v-if="!isTableBaseEmployeeVisible"
    id="formEnterpriseConfiguration"
    class="full mb-5"
  >
    <trazo-form-label-slot labelPosition="top" :required="true" label="Empresa">
      <div
        :class="{
          invalid: isInvalidSelectNull(enterprise)
        }"
      >
        <a-multiselect
          name="enterprise"
          track-by="id"
          label="name"
          v-model="enterprise"
          placeholder="Seleccione una empresa"
          :options="enterprises"
          :allow-empty="false"
          @select="onSelect($event, 'enterprise')"
        />
        <input
          type="hidden"
          name="enterprise_hidden"
          v-model="enterprise_hidden"
        />
      </div>
    </trazo-form-label-slot>
    <b-button @click="onSubmit" :disabled="loadingData" role="submit"
      >Iniciar configuración
    </b-button>
  </form>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import ApiService from "@/core/services/api.service";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";

export default {
  data() {
    return {
      enterprise: null,
      modalShow: false,
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "full_name",
          sortable: true,
          label: "Nombre"
        },
        {
          key: "actions",
          sortable: true,
          label: "Acciones"
        }
      ],
      enterprises: [],
      employees: [],
      loadingEmployees: false,
      isTouchedForm: false,
      enterprise_hidden: "",
      isTableBaseEmployeeVisible: false,
      fv: null,

      isVisibleAssignEmployeeDocuments: false,
      periods: [],
      loadingData: false
    };
  },
  methods: {
    formConfiguration() {
      const formEnterpriseConfiguration = KTUtil.getById(
        "formEnterpriseConfiguration"
      );
      this.fv = formValidation(formEnterpriseConfiguration, {
        fields: {
          enterprise_hidden: {
            validators: {
              notEmpty: {
                message: "La empresa es obligatoria"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    },
    // Events
    onSubmit() {
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        this.loadingData = true;
        this.$emit("selectEnterprise", this.enterprise);
        this.loadingData = false;
        this.formConfiguration();
      });
      this.fv.on("core.form.invalid", () => {
        this.isTouchedForm = true;
      });
    },
    onSelect(value, itemName) {
      this.$data[itemName] = value;
      if ("id" in value) {
        this.$data[`${itemName}_hidden`] = value.id;
      }
      this.fv.validate();
    },
    getEnterprises() {
      ApiService.get("api/enterprise").then(({ data }) => {
        this.enterprises = data;
      });
    },
    // Validations
    isInvalidSelectNull(selectedItems) {
      return this.isTouchedForm && selectedItems === null;
    }
  },
  mounted() {
    this.loadingData = false;
    this.getEnterprises();
    this.formConfiguration();
  },
  components: {
    TrazoFormLabelSlot
  }
};
</script>
